import { NotificationItem } from './notification-item';

export class NotificationList {
  updated: number;
  uwfid: string;
  hasPriorityMsg: boolean;
  badgeCount: number;
  notifications: Array<NotificationItem>;

  constructor(nListObject) {
    this.updated = nListObject.updated;
    this.uwfid = nListObject.uwfid;
    this.hasPriorityMsg = nListObject.hasPriorityMsg;
    this.badgeCount = nListObject.badgeCount;
    this.notifications = nListObject.notifications;
  }
}
