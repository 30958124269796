import { Item } from './item';

export class Collection extends Item {
    public apps: Item[];

    constructor(cardObj: any) {
        super(cardObj); // Call the item constructor first
        this.apps = cardObj.apps || null;
    }
}
