// Import the version info
import { versionInfo } from './version';

export const environment = {
  production: true,
  testing: false,
  versionInfo: versionInfo,
  ga_id: 'UA-111367933-1',
  myuwf_url: 'https://my.uwf.edu',
  res_url: 'https://res.uwf.edu',
  uwf_blue: '#004C97',
  access_token: 'access_token',
  default_timeout: 5000, // Used by healthcheck, http service, etc.
  search_debounce: 300,

  // Notifications and healthcheck
  badge_refresh_short_interval: 5000,   // 5 seconds
  badge_refresh_short_timeout: 30000,   // 30 seconds
  badge_refresh_long_interval: 2700000, // 45 minutes
  healthcheck_refresh_interval: 300000, // 5 minutes

  // Messages
  PRIORITY_MESSAGE_ALERT: 'You have a priority notification that must be dealt with before you can continue to use this app.',
};
