import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InteropObservable, interval, throwError } from 'rxjs';
import { take, timeout, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertController, ToastController } from '@ionic/angular';
import { Healthcheck } from 'src/app/models/healthcheck';
import { SwUpdate } from '@angular/service-worker';
import {  Router } from '@angular/router';

@Injectable()
export class HealthCheckService {

  // A boolean to keep track of whether or not services have failed.
  public hasFailed: boolean = false;

  // Not available modal
  private notAvailableDisplayed: boolean = false;
  private notAvailableModal;

  // New version modal
  private newVersionDisplayed: boolean = false;
  private newVersionModal;

  // Interval to repeatedly check for availability
  private listenerInterval;

  constructor(
    public http: HttpClient,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private updates: SwUpdate,
    private router: Router,
  ) {

    // Listen for service worker to let us know there's a new app available
    if (updates) {
      updates.available.subscribe(event => {
        this.alertNewVersion();
      });
    }

  }

  /**
   * Verify MyUWF's health initially and on a schedule
   */
  public async init() {
    this.verifyMyUWF();

    // On a schedule, re-verify MyUWF's availability
    // comment
    this.listenerInterval = interval(environment.healthcheck_refresh_interval)
      .subscribe(num => {
        this.verifyMyUWF();
      });
  }

  // Verifies MyUWF's availability and version
  public async verifyMyUWF() {
    try {
      const response = await this.check();
      // If we don't get a response at all...
      if (!response) {
        // MyUWF is sick, tell the user and stop them from using the app!
        this.alertNotAvailable();
        // Remember that MyUWF has failed
        this.hasFailed = true;

        // If we do get a response back
      } else {
        // If MyUWF had previously failed, mark it operational
        if (this.hasFailed) {
          this.hasFailed = false;
          this.alertNowFunctional();
        }

        if (this.updates) {
          this.updates.checkForUpdate();
        }

      }
    } catch (err) {
      console.log("MyUWF is unavailable");

    }
  }

  /**
   * Calls healthcheck for myuwf
   * @returns a Healthcheck object
   */
  private check(): Promise<Healthcheck> {
    return new Promise((resolve, reject) => {
      // Call the service
      this.http.get(environment.myuwf_url + '/healthcheck')
        .pipe(
          take(1),
          timeout(3000),
          catchError((error: HttpErrorResponse) => {
            // MyUWF is sick, tell the user and stop them from using the app!
            this.alertNotAvailable();
            // Remember that MyUWF has failed
            this.hasFailed = true;
            const e = new Error("MyUWF is unavailable");
            reject(e);
            throw e;
          })
        ).subscribe(response => {
          // Resolve whenever we get the first result back
          resolve(response as Healthcheck);
        });
    });
  }

  // Show an alert that MyUWF isn't available.
  // Allow the user to retry communication.
  private async alertNotAvailable() {
    if (!this.notAvailableDisplayed) {
      this.notAvailableDisplayed = true;
      this.notAvailableModal = await this.alertCtrl.create({
        subHeader: 'MyUWF is unavailable right now.',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Retry',
            handler: () => {
              this.notAvailableDisplayed = false;
              this.verifyMyUWF();
            }
          }
        ]
      });
      await this.notAvailableModal.present();
    }
  }

  // Show an alert that a new version of MyUWF is available.
  // Allow the user to reload the app.
  private async alertNewVersion() {
    if (!this.newVersionDisplayed) {
      this.newVersionDisplayed = true;
      this.newVersionModal = await this.alertCtrl.create({
        subHeader: 'A new version of MyUWF is available.',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Reload MyUWF',
            handler: () => {
              this.newVersionDisplayed = false;
              // Tell the service worker to reload the app, then refresh the screen
              this.updates.activateUpdate().then(() => document.location.reload());
            }
          }
        ]
      });
      await this.newVersionModal.present();
    }
  }

  // Tell the user that the app is functional once again
  private async alertNowFunctional() {
    // Disable the not available modal if it's present
    if (this.notAvailableModal) {
      this.notAvailableModal.dismiss();
    }

    this.notAvailableDisplayed = false;

    let toast = await this.toastCtrl.create({
      message: 'Access to MyUWF has been restored.',
      duration: environment.default_timeout
    });
    await toast.present();
    this.router.navigate(['/']);
  }

}
