import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { AuthService } from './services/auth/auth.service';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Event as NavigationEvent } from '@angular/router';
import { BrowserService } from './services/browser/browser.service';
import { HttpService } from './services/http/http.service';
import { HealthCheckService } from 'src/app/services/health-check/health-check.service';
import { AnalyticsService } from './services/analytics/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  constructor(
    private analytics: AnalyticsService,
    private platform: Platform,
    private auth: AuthService,
    private browserService: BrowserService,
    private httpService: HttpService,
    private router: Router,
    // private firebase: Firebase,
    private healthCheckService: HealthCheckService
  ) {
    this.initializeApp();
  }

  public hasLoggedIn: boolean;

  initializeApp() {
    this.analytics.init();
    this.platform.ready().then( async() => {

      this.healthCheckService.init();

      // Listen for auth state changes,
      // do some cleanup and send back to login screen if needed
      this.auth.authenticationState.subscribe( async (state) => {
        if (state) {
          await this.httpService.setHeaderToken(); // Sets the JWT so our API calls are authenticated
          this.hasLoggedIn = true;
        } else {
          await this.httpService.setHeaderToken(); // Will update / clear the JWT token
          // Make sure that the cached auth status has loaded.
          // If we had been logged in (hasLogedIn was set before), make sure we log out.
          if (state !== null && this.hasLoggedIn) {
              this.hasLoggedIn = false;  // Reset the logged in flag
              this.router.navigate(['login']);
          }
        }
      });
    });
  }
}
