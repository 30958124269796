import { Item } from './item';

export class PagedItemList {
  results: Item[];
  total: number;    // Total number of results that are available

  constructor(results: Item[], total:number) {
    this.results = results;
    this.total = total;
  }
}
