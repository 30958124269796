import { Injectable } from '@angular/core';
import { LoadingService } from '../loading/loading.service';

/** Allows us to send the user to an external web page */
@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(private loadingService: LoadingService) {  }

  public async visitURL(url: string) {
    // Don't worry about credentials at all if we're on a web browser, just hit the URL
    this.navigate(url);
  }

  /**
   * Navigates the user to a URL
   * @param url The URL we are visiting
   */
  private navigate(url: string): any {
    window.open(url,'_blank','noopener');
    this.loadingService.stopLoading();
  }
}
