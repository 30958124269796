import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { WebLoadingGuardService } from './guards/web-loading-guard/web-loading-guard.service';
import { PriorityMessageWebGuard } from './guards/priority-message-web-guard/priority-message-web.guard';

const routes: Routes = [
  // Native users will be redirected appropriately by the authguard
  // Web users will see the "home" module after the authguard is finished
  { path: '', 
   loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
   // Perform pre-loading logic on web
   canActivate: [WebLoadingGuardService, PriorityMessageWebGuard],
  },

  // Displays the user's notifications
  { path: 'notifications', 
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule),
    // Perform pre-loading logic on web
    canActivate: [WebLoadingGuardService]
  },

  // Displays a list of items, used in all app versions
  { path: 'item-list', 
    loadChildren: () => import('./pages/item-list/item-list.module').then(m => m.ItemListPageModule),
    // Perform pre-loading logic on web
    canActivate: [WebLoadingGuardService, PriorityMessageWebGuard]
  },
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
