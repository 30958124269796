import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { MyuwfService } from '../../services/myuwf/myuwf.service';

@Injectable({
  providedIn: 'root'
})
export class PriorityMessageWebGuard implements  CanActivate {
  constructor(private myUwfService: MyuwfService, private router: Router) {}
  canActivate(): UrlTree | boolean { 
    if (this.myUwfService.priorityMessageMode ) { 
      return this.router.createUrlTree(['/notifications']);
    }
    return true; 
  }
}
