import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { Platform } from '@ionic/angular';
import { HttpService } from 'src/app/services/http/http.service';
import { MyuwfService } from 'src/app/services/myuwf/myuwf.service';

@Injectable({
  providedIn: 'root'
})
export class WebLoadingGuardService implements CanActivate {

  private hasLoggedIn: boolean = false;

  constructor(
    private auth: AuthService,
    private platform: Platform,
    private httpService: HttpService,
    private myuwfService: MyuwfService
  ) { }

  // Will pass through native requests
  // Will delay web requests until web token is loaded
  canActivate(): Promise<boolean> | boolean {
    // Check to see if we have a valid token in place. If we have one, just move on.
    return this.auth.loadWebToken().then(async (haveToken: boolean) => {
      // We only want to do this if this is our first sign in (don't do this every nav action)
      if (haveToken && !this.hasLoggedIn) {
        await this.myuwfService.login();
        await this.httpService.setHeaderToken();
        this.hasLoggedIn = true;
      }
      return true;
    });
  }
}
