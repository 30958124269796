// Standard Imports
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Custom Imports
import { Storage, IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { HealthCheckService } from 'src/app/services/health-check/health-check.service';

import { ServiceWorkerModule } from '@angular/service-worker';
import { LoadingScreenInterceptor } from './interceptors/loading.interceptor';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';

export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get(environment.access_token);
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingAnimationComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider : {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage],
      }
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    HealthCheckService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
