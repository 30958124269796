import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AlertController, Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { timeout } from 'rxjs/operators';

const TOKEN_KEY = environment.access_token;
@Injectable({
  providedIn: 'root'
})
/** Class that facilitates authentication for MyUWF. */
export class AuthService {

  /** A behavior subject that contains the current user */
  public user = new BehaviorSubject(null);

  /** The token that is currently being used for authentication. */
  public token: string = null;

  /**  A behavior subject that will contain a boolean indicating if the user is signed in. */
  public authenticationState = new BehaviorSubject(null);

  constructor(
    protected http: HttpClient,
    private helper: JwtHelperService,
    private storage: Storage,
    public platform: Platform,
    private alertController: AlertController
  ) {}

  /** Check to see if the user has a valid token in storage 
  * @returns {Promise<boolean>} A promise for a boolean indecating whether or not a token was found
  */
  public async loadTokenFromStorage(): Promise<boolean> {
    return this.storage.get(TOKEN_KEY).then(token => {
      if (token) {
        let decoded = this.helper.decodeToken(token);
        let isExpired = this.helper.isTokenExpired(token);
        if (!isExpired) {
          this.user.next(decoded);
          this.token = token;
          this.authenticationState.next(true);
        } else {
          this.authenticationState.next(false);
          this.storage.remove(TOKEN_KEY)
            .catch(error => {
              this.showAlert(error.message);
            });
        }
        return true;
      } else {
        this.authenticationState.next(false);
        return false;
      }
    }).catch(error => {
      this.showAlert(error.message);
      return false;
    });
  }

  /**
   * Get the current token, either from memory or from storage
   * @returns {Promise<string>} A promise for the token value if one exists,
   * otherwise it returns an empty string in the promise
   */
  public getToken(): Promise<string> {
    // If the current token variable has the token, return that
    if (this.token) {
      return new Promise( (resolve) => resolve(this.token) );
    // Else if we find it in storage use that instead
    } else {
      return this.storage.get(TOKEN_KEY).then((storageToken) => {
        if(storageToken) {
          this.token = storageToken;
          return storageToken;
        } else {
          return "";
        }
      });
    }
  }

  /**
   * Allows you to check if the user is currently authenticated
   * @returns {boolean} Indicating whether the person is currently authenticated.
   */
  isAuthenticated(): boolean {
    return this.authenticationState.value;
  }

  /**
   * Allows you to check if the user is an employee (based on roles in the JWT)
   * @returns {Promise<boolean>} Indicating whether the person is currently authenticated.
   */
  async isEmployee(): Promise<boolean> {
    if ( this.isAuthenticated() ) {
      let token = await this.getToken();
      let decoded = this.helper.decodeToken(token);
      return decoded.roles && (decoded.roles.includes('10') || decoded.roles.includes('14'));
    } else {
      return false;
    }
  }

  /**
   * Returns an existing web token or gets a new one from the server.
   * This is based on CAS authentication, no credentials needed
   * @returns {Promise<boolean>} A promise for a boolean indicating if a token was loaded.
   */
  async loadWebToken(): Promise<boolean> {
    // Check expiration on the in-memory token if we have one to check
    let isExpired = true;
    if (this.token) {
      isExpired = this.helper.isTokenExpired(this.token);
    }
    // If an existing one is in memory, just return true and move on
    if (this.token && !isExpired) {
      return true;
      // Otherwise, go try to get one
    } else {
      let endpoint = '/api/user';
      let url = environment.myuwf_url + endpoint;
      let options = {headers: new HttpHeaders()};
      return this.http.get(url, options)
      .pipe(
        timeout(environment.default_timeout)
      // Converting to a promise since this is an async function
      ).toPromise().then( (response: any) => {
        if (response.status === 'success') {
          // We store the token here in memory but NOT in storage
          this.token = response.data.token;
          this.user.next(this.helper.decodeToken(response.data.token));
          this.authenticationState.next(true);
          return true;
        }
        return false;
      }).catch( (e) => {
        if (e.error && e.error.message) {
               this.showAlert(e.error.message);
        }
        return false;
      });
    }
  }

  /** Logs the user out by removing any local tokens and setting auth state to false
   * It will also redirect the user to the logout endpoint if on the web
   */
  logout() {
    this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
      window.open(`${environment.myuwf_url}/logout?v=${environment.versionInfo.hash}`, '_self');
    });
  }

  /**
   * Shows an alert message to the user, used if there is an error obtaining an auth token
   * @param message The message to show
   */
  showAlert(message: string) {
    let alert = this.alertController.create({
      header: message,
      buttons: ['Ok']
    });
    alert.then(alert => alert.present());
  }
}
