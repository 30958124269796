export class Preferences {
  listView: string;
  listViewMobile: string;
  theme: string;
  tourViewed: string;

  // Keys in the input object are optional
  constructor ({
    listView = "false", 
    listViewMobile = "true",
    theme = "default", 
    tourViewed = "false"
  } : {listView?:string, listViewMobile?:string, theme?:string, tourViewed?:string}) {
    this.listView = listView;
    this.listViewMobile = listViewMobile;
    this.theme = theme;
    this.tourViewed = tourViewed;
  }

}