import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject, interval, timer, merge } from 'rxjs';
import { HttpService } from '../http/http.service';
import { Collection } from '../../models/collection';
import { Item } from '../../models/item';
import { HttpParams } from '@angular/common/http';
import { NotificationList } from 'src/app/models/notification-list';
import { Preferences } from 'src/app/models/preferences';
import { PagedItemList } from 'src/app/models/paged-item-list';
import { Announcement } from 'src/app/models/announcement';

@Injectable({
  providedIn: 'root'
})

export class MyuwfService {
  private destroyed$: Subject<{}> = new Subject();       // For monitoring the destruction of the component.

  // "Hot" observable representing notifications actions so other items can subscribe and react to it
  public notificationsSubject: Subject<NotificationList> = new Subject();
  public notificationAction$: Observable<NotificationList> = this.notificationsSubject.asObservable();

  // Observable of user preferences, based on behavior subject so it always has a value
  public preferencesSubject: BehaviorSubject<Preferences> = new BehaviorSubject(
    new Preferences({ listView: "false", theme: "default", tourViewed: "true" })
  );
  public preferences$: Observable<Preferences> = this.preferencesSubject.asObservable();

  // Used to track if we are in a lock-down priority mode or not
  public priorityMessageMode: boolean = false;

  private lastNotificationDate: number = 0;

  // Interval to repeatedly check for notifications
  private notificationsInterval;
  private notificationsReloadInterval;

  // Subject that tracks the refresh rate of our notifications interval
  private notificationsRefreshRate$ = new BehaviorSubject<number>(environment.badge_refresh_long_interval);

  constructor(private http: HttpService) { }

  ngOnInit() {

    // On a schedule, trigger a notifications recalc and check for the results
    this.notificationsInterval = interval(environment.badge_refresh_long_interval)
      .subscribe(num => {
        this.refreshNotifications();
        this.watchForNotifications();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(); // Emit a notification on the subject that this component is destroyed.
  }

  /**
   * Searches for all items that match a query string and filter combination
   * @param query The search term the user is submitting
   * @param filter A particular item type we want to restrict searches to
   * @param from The starting record we want our results to return
   * @param size The number of records we want returned
   * @returns {PagedItemList} An paged list of Items representing the search results
   */
  search(query: string, filter: string = '', from: string = '0', size: string = '10'): Observable<PagedItemList> {
    let endpoint: string = '/api/search';
    let params = new HttpParams() // Set up parameters for this query
      .set('q', query)
      .set('filter', filter)
      .set('from', from)
      .set('size', size);
    let url: string = environment.myuwf_url + endpoint;
    return this.http.get(url, params)
      .pipe(map((response: any) => {
        return new PagedItemList(this.buildItemArray(response.data.results), response.data.total);
      }));
  }

  /**
   * Gets the search history for this user
   * @param from The starting record we want our results to return
   * @param size The number of records we want returned
   * @returns {PagedItemList} An paged list of Items to display on the search page as recently visted
   */
  getRecentlyVisited(from: string = '0', size: string = '10'): Observable<PagedItemList> {
    let endpoint: string = '/api/history';
    let url: string = environment.myuwf_url + endpoint;
    let params = new HttpParams().set('from', from).set('size', size); // Set up from/size parameters
    return this.http.get(url, params)
      .pipe(map((response: any) => {
        return new PagedItemList(this.buildItemArray(response.data.results), response.data.total);
      }));
  }

  /**
   * Gets all home items for this user
   * @returns {Item|Array} An array of Items to display on the user's home screen
   */
  getHomeItems(): Observable<Item[]> {
    let endpoint: string = '/api/featuredAndHome';
    let url: string = environment.myuwf_url + endpoint;
    return this.http.get(url)
      .pipe(map((response: any) => {
        if (response.status === 'success') {
          return this.buildItemArray(response.data);
        }
      }));
  }

  /**
 * Gets all items visible to this user
 * @param from The starting record we want our results to return
 * @param size The number of records we want returned
 * @returns {Item|Array} An array of all Items the user can see
 */
  getAllItems(from: string = '0', size: string = '50'): Observable<PagedItemList> {
    let endpoint: string = '/api/item';
    let params = new HttpParams() // Set up parameters for this query
      .set('from', from)
      .set('size', size);
    let url: string = environment.myuwf_url + endpoint;
    const results = this.http.get(url, params)
      .pipe(map((response: any) => {
        return new PagedItemList(this.buildItemArray(response.data.results), response.data.total);
      }));
    return results;
  }

  /**
    * Requests a notifications refresh from MyUWF (via badger)
    */
  refreshNotifications(): void {
    let endpoint: string = '/api/notification/refresh';
    let url: string = environment.myuwf_url + endpoint;
    this.http.get(url)
      .subscribe(() => {
      });
  }

  /** Repeatedly check MyUWF API for a recalculated badge */
  watchForNotifications(): void {
    // Set a stop conditions subject to stop the quick refreshes
    // This will stop when we get new notifications or we reach a timeout.
    const stopConditions$ = merge(
      this.notificationsSubject,
      timer(environment.badge_refresh_short_timeout)
    ).pipe(
      tap(s => console.log("Notification watching stopped", s))
    );

    // Check for new notifications quickly until we reach a stop condition
    this.notificationsReloadInterval =
      interval(environment.badge_refresh_short_interval)
        .pipe(takeUntil(stopConditions$))
        .subscribe(num => {

          // Get the notifications - need to subscribe for it to work
          this.getNotifications().pipe(take(1))
            .subscribe((nList: NotificationList) => {
              return;
            });

        });
  }

  /**
   * Loads the latest notifications for this user.
   * Will send the notifications to the subject if they have changed.
   * @returns A boolean indicating whether a new notification group was obtained
   */
  getNotifications(): Observable<NotificationList> {
    let endpoint: string = '/api/notification';
    let url: string = environment.myuwf_url + endpoint;
    return this.http.get(url)
      .pipe(map((response: any) => {
        if (response.status === 'success') {
          let nList = new NotificationList(response.data);

          if (nList.updated > this.lastNotificationDate) {
            // Store the new notification date
            this.lastNotificationDate = nList.updated;

            // Update our priority flag
            this.priorityMessageMode = nList.hasPriorityMsg;

            // Send the notification list on to those who are subscribed to it
            this.notificationsSubject.next(nList);
          }
          return nList;
        }
      }));
  }

  /**
   * Gets all current announcements to be displayed
   * @returns {Announcement[]} The announcement list we requested
   */
  getAnnouncements(): Observable<Announcement[]> {
    let endpoint: string = '/api/announcements';
    let url: string = environment.myuwf_url + endpoint;
    return this.http.get(url).pipe(map((response: any) => {
      return response.data;
    }));
  }

  /** Takes the value of a preference a stores it with the API. Refreshes values afterwards */
  setPreference(name: string, value: string) {
    let endpoint: string = '/api/preference';
    let url: string = environment.myuwf_url + endpoint;
    return this.http.post(url, { name: name, value: value })
      .pipe(take(1)).subscribe((response: any) => {
        if (response.status === 'success') {
          setTimeout(async () => {
            this.loadPreferences().subscribe();
          }, 500); // Re-load prefs from the web after a half second 
        }
      });
  }

  /** Loads the user's preferences from myuwf and returns them */
  loadPreferences(): Observable<Preferences> {
    let endpoint: string = '/api/preference';
    let url: string = environment.myuwf_url + endpoint;

    return this.http.get(url)
      .pipe(map((response: any) => {
        if (response.status === 'success') {
          this.preferencesSubject.next(response.data);
          return response.data;
        }
      }));
  }

  /**
   * Gets an collection.
   * @param id The id number of the collection we are viewing.
   * @returns {Collection}
   */
  getCollection(id: number): Observable<Collection> {
    let endpoint: string = '/api/item/' + id + '?expand=apps';
    let url: string = environment.myuwf_url + endpoint;
    return this.http.get(url)
      .pipe(map((response: any) => {
        if (response.status === 'success') {
          let collectionData = response.data[0];
          // We need to create actual item objects before creating the collection
          collectionData.apps = this.buildItemArray(collectionData.apps);
          return new Collection(collectionData);
        }
      }));
  }

  /**
   * Lets myuwf know that we are visiting the item
   * @param item The item we are visiting.
   */
  itemVisited(item: Item): Promise<void> {
    // Build up an object of the items we need to save for this visit
    const body = {
      platform: 'WEB',
      id: item.id,
      type: item.type,
      title: item.title,
      links: item.links,
      iconPath: item.iconPath,
      iconFilename: item.iconFilename,
      timestamp: new Date().getTime()
    };

    const endpoint: string = '/api/history/visited';
    const url: string = environment.myuwf_url + endpoint;

    return this.http.post(url, body).toPromise().then(() => {});
  }

  /**
   * Converts an array of objects retrieved from the web service into proper item objects
   * @param data An array of objects that will be used to build these items
   * @returns {Item|Array} An array of item objects
   */
  private buildItemArray(data: any[]): Item[] {
    // Loop over all items, converting each to either an item or collection based on type
    let itemArray: Item[] = [];
    if (Array.isArray(data)) {
      for (let item of data) {
        let itemInstance = item.type === 'collection' ? new Collection(item) : new Item(item);
        if (item.notifications && Array.isArray(item.notifications)) {
          itemInstance.badgeCount = item.notifications.length;
        }
        itemArray.push(itemInstance);
      }
    }
    return itemArray;
  }

  /** Clears the user's search history */
  public clearVisitedHistory() {
    let endpoint: string = '/api/history/clear';
    let url: string = environment.myuwf_url + endpoint;
    return this.http.get(url);
  }

  /** Tells myuwf we have logged in */
  public async login() {
    const body = {};
    let endpoint: string = '/api/login';
    let url: string = environment.myuwf_url + endpoint;
    return this.http.post(url, body).toPromise().then(() => {});
  }

  /** Tells myuwf we have logged out */
  public async logout() {
    let endpoint: string = '/api/logout';
    let url: string = environment.myuwf_url + endpoint;
    return this.http.get(url).toPromise().then(() => {});
  }

}
