import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { retryWhen, tap, timeout, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { genericRetryStrategy } from 'src/app/utilities/rxjs-utils';
import { AlertController } from '@ionic/angular';
import { HealthCheckService } from '../health-check/health-check.service';

/** This HTTP service is a common way for services to make HTTP calls. 
 * It handles attatching the JWT token and also implements a retry strategy in case a call fails.
 * There is also a common error handler that will show the user an alert asking to re-load the page if a 401 is encountered.
 * This means that the user's JWT has expired, so they probably need to re-authenticate before being allowed to get a new one.
 */
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  // Setting up headers for JWT insertion
  private headers: HttpHeaders = null;

  //Tracking if we have shown the session expired message yet
  private sessionExpiredMessageShown = false;

  constructor(private http: HttpClient, 
    private authService: AuthService,
    private healthCheckService: HealthCheckService,
    private alertController: AlertController
    ) {}

  /** Sets the headers to use our token from the auth service */
  async setHeaderToken(): Promise<boolean> {
    let token = await this.authService.getToken();
    if (token) {
      this.headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'myuwf-platform': 'WEB'
      });
      return true;
    } else {
      // Headers should not contain a token if we don't have one
      // Replace the headers with a new object without the authorization value
      this.headers = new HttpHeaders({
        'myuwf-platform': 'WEB'
      });
      return false;
    }
  }

  /**
   * A pass-through get request that enforces uniform timout and error handling for all http requests
   * @param url The url that we are visiting
   * @param params An HttpParams object with any params we are sending up, defaults to an empty one if not passed in
   */
  get(url: string, params: HttpParams = new HttpParams() ): Observable<any> {
    return this.http.get(url, {params: params, headers: this.headers})
      .pipe(
        timeout(environment.default_timeout),
        retryWhen(genericRetryStrategy()),
        tap( (response: any) => {
          if (response.status !== 'success') {
            // TODO: there used to be a RUM log here
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  /**
   * A pass-through put request that enforces uniform timout and error handling for all http requests
   * @param url The url that we are visiting
   * @param params An object with the body content we are posting
   */
  put(url: string, body: object): Observable<any> {
    return this.http.put(url, body, {headers: this.headers})
      .pipe(
        timeout(environment.default_timeout),
        retryWhen(genericRetryStrategy()),
        tap( (response: any) => {
          if (response.status !== 'success') {
            // TODO: there used to be a RUM log here
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  /**
   * A pass-through post request that enforces uniform timout and error handling for all http requests
   * @param url The url that we are visiting
   * @param params An object with the body content we are posting
   */
  post(url: string, body: object): Observable<any> {
    return this.http.post(url, body, {headers: this.headers})
      .pipe(
        timeout(environment.default_timeout),
        retryWhen(genericRetryStrategy()),
        tap( (response: any) => {
          if (response.status !== 'success') {
            // TODO: there used to be a RUM log here
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  /**
   * A pass-through get request that enforces uniform timout and error handling for all http requests
   * @param url The url that we are visiting
   * @param params An HttpParams object with any params we are sending up, defaults to an empty one if not passed in
   */
  delete(url: string, params: HttpParams = new HttpParams() ): Observable<any> {
    return this.http.delete(url, {params: params, headers: this.headers})
      .pipe(
        timeout(environment.default_timeout),
        retryWhen(genericRetryStrategy()),
        tap( (response: any) => {
          if (response.status !== 'success') {
            // TODO: there used to be a RUM log here
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  /**
   * A common error handler for http methods. Depending on the error type it will sometimes log the error
   * or possibly show the user an alert message.
   * @param error The error that was encountered by the httpClient
   */
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // Show the expired alert if it's a 401 error
    if(error.status && error.status === 401) {

      // If we haven't shown the session expired message yet, show it now.
      // Future calls to this will skip this alert message.
      // Clicking "ok" on the alert will cause the app to reload, 
      // clearing the message and setting this flag back to false.
      if(!this.sessionExpiredMessageShown) {
        this.sessionExpiredMessageShown = true;
        this.showAlert(
          "Session Expired", 
          "Your MyUWF session has expired. Click ok to log in again."
        );
      }

    } else {
      this.healthCheckService.verifyMyUWF();
    }
    return throwError(errorMessage);
  }

  /**
   * Shows an ionic alert message to ask users to reload the page
   * @param header The text to show in the header of the message
   * @param message The text to show in the message body
   */
  showAlert(header: string, message: string) {
    let alert = this.alertController.create({
      header: header,
      message: message,
      buttons: [{
          text: 'Ok',
          handler: () => {
            window.location.href = "/login";
          }
        }
      ]
    });
    alert.then(alert => alert.present());
  }
}
