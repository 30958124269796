import { Link } from './link';
import { Notification } from 'src/app/models/notification';

export class Item {
  public id: string;
  public status: string;
  public type: string;
  public title: string;
  public descriptions: string[];
  public links: Link[];

  // Image
  public iconPath: string;
  public iconFilename: string;

  // Location
  public longitude: number;
  public latitude: number;

  // Featured
  public isFeatured: boolean;

  // Alias
  public isAlias: boolean;

  // Lists
  public lists: string[];
  public canBeFavorited: boolean;

  // Notifications
  public badgeCount: number = 0;

  constructor(itemObj: any) {
    this.id = itemObj.id;
    this.status = itemObj.status;
    this.type = itemObj.type;
    this.title = itemObj.title;
    this.descriptions = itemObj.descriptions || [];
    this.links = itemObj.links || [];
    this.iconPath = itemObj.iconPath || '';
    this.iconFilename = itemObj.iconFilename || '';
    this.longitude = itemObj.longitude;
    this.latitude = itemObj.latitude;
    this.isFeatured = itemObj.isFeatured || false;
    this.isAlias = itemObj.isAlias || false;
    this.lists = itemObj.lists;
    this.canBeFavorited = itemObj.canBeFavorited || false;
    this.badgeCount = itemObj.badgeCount || 0;
  }

  public get isInList(): boolean {
    return this.lists.length > 0;
  }

  /* Adds a list to the lists array */
  public addList (listId: string): void { 
    this.lists.push(listId);
  };

  /* Removes a list from the lists array */
  public removeList(listId: string): void {
    for( var i = 0; i < this.lists.length; i++){
      if(this.lists[i] === listId){
        this.lists.splice(i,1);
      }
    }
  }

}
